// this was adapted from "Learn React Context Explained in 10 minutes - useContext"
// seen at https://www.youtube.com/watch?v=hUhWtYXgg0I with code available on
// GitHub at https://github.com/CodingWith-Adam/react-context-tutorial

import { createContext, useState, useContext } from "react";

const AccountContext = createContext();
const UpdateAccountContext = createContext();

export const useAccount = () => {
  return useContext(AccountContext);
};

export const useUpdateAccount = () => {
  return useContext(UpdateAccountContext);
};

export const WalletProvider = ({ value, children }) => {
  const [account, setAccount] = useState(value);
  return (
    <AccountContext.Provider value={account}>
      <UpdateAccountContext.Provider value={setAccount}>
        {children}
      </UpdateAccountContext.Provider>
    </AccountContext.Provider>
  );
};

export default WalletProvider;