import Container from 'react-bootstrap/esm/Container';


export default function Blog() {
    return (
        <Container>
            <p className='header'>Under Contruction</p>

        </Container>
    )

}