import Container from "react-bootstrap/Container";
import WalletConnect from "../components/WalletConnect"

export default function Home() {
    return (
        <Container>
            <header className="header-container text-center">
                <p className="header"> 📜 CareerTracts </p>
                <p className="sub-text">On-Chain Employee Management</p>
                <p>Distributed ledger employee payroll platform with NFT role ownership</p>
            </header>

            <WalletConnect onAddressChanged={address => {}} className="text-center"/>
        </Container>
    );
}