import React from "react";
import {BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

// Bootstrap imports
import Container from 'react-bootstrap/Container';
// import Image from "react-bootstrap/Image";

//Component imports
import WalletProvider from './contexts/AccountProvider';
import NavigationBar from './components/NavigationBar';

//Import pages
import Home from './pages/Home';
import RoadMap from './pages/RoadMap';
import Blog from './pages/Blog';

function App() {

  return (
  <Container fluid className="App">
    <BrowserRouter>
      <WalletProvider>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/roadmap" element={<RoadMap />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </WalletProvider>
    </BrowserRouter>
  </Container>
  );
}

export default App;