import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';

function NavigationBar() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand as={NavLink} to="/"> 📜 CareerTracts </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={NavLink} to="/roadmap">Roadmap</Nav.Link>
            <Nav.Link as={NavLink} to="/blog">Development Blog</Nav.Link>
            <Nav.Link as={NavLink} to="/analytics">Analytics</Nav.Link>

            <NavDropdown title="Employee" id="collasible-nav-dropdown">
              <NavDropdown.Item as={NavLink} to="/employee/payroll">Payroll</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/employee/settings">Settings</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Company" id="collasible-nav-dropdown">
              <NavDropdown.Item as={NavLink} to="/company/payroll">Payroll</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/company/manage-employees">Manage Employees</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/company/settings">Settings</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={NavLink} to="/company/register">Register Company</NavDropdown.Item>
            </NavDropdown>
          </Nav>

        </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;