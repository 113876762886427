import ModalImage from "react-modal-image";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from "react-bootstrap";

const RoadMap = () => {
    return ( 
        <Container id="roadmap">
            <p className="sub-text bold">Current Design Diagrams</p>

            <Row>
                <Col>
                    <ModalImage smallSrcSet={"https://gateway.pinata.cloud/ipfs/Qmc82QcckptwbWaWv7eXbSnqgWLzs2HpZfMVBGaceQXVFf"}
                            large={"https://gateway.pinata.cloud/ipfs/QmPHv6vuxTiwGUA6bwazs4VejEUWefm88nEtaFQxKecDmY"}
                            alt="Webpage Flow" />
                    <p className="sub-text">Webpage Flow</p>
                </Col>
                <Col>
                    <ModalImage smallSrcSet={"https://gateway.pinata.cloud/ipfs/QmY1LyA589vXrzn6vWV165MKAea8ACw926rb5V2SXqqGX8"}
                                large={"https://gateway.pinata.cloud/ipfs/QmUhaXCCXuiNRFpTSvbeFeBrgAtSMnNeKfW5XsFNYNFyzf"}
                                alt="Contract Flow" />
                    <p className="sub-text">Contract Flow</p>
                </Col>
            </Row>
        </Container>
    );
}

export default RoadMap;