// original code adapted from walkthrough to make this a component at
// https://betterprogramming.pub/build-a-react-component-for-metamask-auth-10b7ecba5c3f
// source available at https://github.com/ilamanov/metamask-react-auth
//
// the example was adapted to use a shared context

import React, { useEffect } from "react";
import { useAccount, useUpdateAccount } from "../contexts/AccountProvider";

function isMobileDevice() {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

async function connect(onConnected) {
  if (!window.ethereum) {
    alert("Get MetaMask!");
    return;
  }

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  onConnected(accounts[0]);
}

async function checkIfWalletIsConnected(onConnected) {
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    if (accounts.length > 0) {
      const account = accounts[0];
      onConnected(account);
      return;
    }

    if (isMobileDevice()) {
      await connect(onConnected);
    }
  }
}

export default function WalletConnect({ onAddressChanged }) {
  const userAddress = useAccount();
  const setUserAddress = useUpdateAccount();


  useEffect(() => {
    checkIfWalletIsConnected(setUserAddress);
  }, [setUserAddress]);

  useEffect(() => {
    onAddressChanged(userAddress);
  }, [userAddress, onAddressChanged]);

  return userAddress ? (
    <div className="cta-button button-container">
      Connected with <Address userAddress={userAddress} />
    </div>
  ) : (
     <Connect setUserAddress={setUserAddress}/>
  );
}


function Connect({ setUserAddress }) {
  if (isMobileDevice()) {
    const dappUrl = "careertracts.com";
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    return (
      <a href={metamaskAppDeepLink}>
         <button className="cta-button button-container">
           Connect to MetaMask
         </button>
      </a>
    );
  }
    return (
    <button className="cta-button button-conatiner" onClick={() => connect(setUserAddress)}>
      Connect to MetaMask
    </button>
  );
}


function Address({ userAddress }) {
  return (
    <span>{userAddress.substring(0, 5)}…{userAddress.substring(userAddress.length - 4)}</span>
  );
}
